import * as React from 'react';
import {
  usePageSpeedIconWithAction,
  useSpeedDealMenuOpened,
} from '@dpdgroupuk/fmx-ui/components/Page/hooks';
import { useHistory, useRouteMatch } from 'react-router-dom';
import * as ENUM from '@dpdgroupuk/redback-enums';
import { has } from 'lodash';
import { useModal } from '@dpdgroupuk/fmx-ui/components/Dialog';
import { isMobile } from '@dpdgroupuk/fmx-ui/utils/helpers';

import { Chat } from '../../components/Icons';
import { goToChangeDelivery } from '../historyActions';
import { PROBLEM_COMPLITING_REQUEST } from '../../constants/error';

const { REACT_APP_CHATBOT_BUTTON_DELAY = 1000 } = process.env;

const createChatIconWithAction = ({
  setShowChat,
  showChat,
  showChatIcon,
  loadChat,
  setLoadChat,
}) => {
  const action = () => {
    if (!loadChat) {
      setLoadChat(true);
      setShowChat(true);
    } else {
      setShowChat(!showChat);
    }
  };
  return showChatIcon ? [Chat, action] : [];
};

export const useChatIconWithAction = ({
  setShowChat,
  showChat,
  setLoadChat,
  loadChat,
  showChatIcon,
  chatOrigin,
}) => {
  const iconWithAction = React.useMemo(
    () =>
      createChatIconWithAction({
        setShowChat,
        showChat,
        loadChat,
        setLoadChat,
        showChatIcon,
      }),
    [setShowChat, showChat, loadChat, setLoadChat, showChatIcon]
  );
  usePageSpeedIconWithAction(iconWithAction);
  useSpeedDealMenuOpened(showChat ? false : isMobile ? chatOrigin : true);
};

export const useChatBotListener = ({
  setShowChat,
  setChatAvailable,
  setLoadChat,
}) => {
  const { params } = useRouteMatch();
  const history = useHistory();
  const { showModal } = useModal();

  React.useEffect(() => {
    const obj = document.getElementById('dpdgroup-chatbot-fmp');
    const closeChat = () => {
      setShowChat(false);
      setChatAvailable(false);
      setLoadChat(false);
    };

    const listener = function(e) {
      if (e.detail === 'close') {
        closeChat();
      } else if (e.detail === 'available') {
        setChatAvailable(true);
      } else if (e.detail === 'load_error') {
        showModal({
          title: PROBLEM_COMPLITING_REQUEST,
          description: null,
        });
        closeChat();
      } else if (e.detail.id && has(ENUM.PARCEL_ACTION_CODE, e.detail.id)) {
        setLoadChat(false);
        goToChangeDelivery({
          actionCode: e.detail.id,
          parcel: { parcelCode: params.parcelId },
          history,
        });
      }
    };
    obj.addEventListener('dpd/cbot/fmp-action', listener);
    return () => {
      obj.removeEventListener('dpd/cbot/fmp-action', listener);
    };
  }, [setShowChat, setChatAvailable, setLoadChat, params, history, showModal]);
};

export const useDelay = (time = parseInt(REACT_APP_CHATBOT_BUTTON_DELAY)) => {
  const [delay, setDelay] = React.useState(false);
  const isMounted = React.useRef(false);

  React.useEffect(() => {
    isMounted.current = true;

    setTimeout(() => {
      if (isMounted.current) {
        setDelay(true);
      }
    }, time);

    return () => {
      isMounted.current = false;
    };
  }, [time]);

  return delay;
};
